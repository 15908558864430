<template>
  <section class="user-guide-second-step">
    <div class="user-guide__input-wrapper">
      <input
        class="user-guide__input"
        type="text"
        :placeholder="$translate('user_guide.step_2.product_title')"
        :value="update_product_title"
        @input="update_product_title = $event.target.value"
      />
    </div>
    <textarea
      class="user-guide-second-step__textarea"
      :placeholder="$translate('user_guide.step_2.product_description')"
      :value="updated_product_description"
      @input="updated_product_description = $event.target.value"
    />
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  computed: {
    update_product_title: {
      get() {
        return this.data.product_title
      },
      set(value) {
        this.$emit("update_value", value, "product_title")
      }
    },
    updated_product_description: {
      get() {
        return this.data.product_description
      },
      set(value) {
        this.$emit("update_value", value, "product_description")
      }
    }
  },
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-guide-second-step {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $double-default-size;

    &__textarea {
      width: 100%;
      max-width: 500px;
      height: 150px;
      margin: 0 auto;
      padding: $half-default-size $default-size;
      border: 1px solid $pure-black;
      border-radius: $border-radius;
      font-size: $font-size--medium-large;
      text-align: left;
      font-family: $main-font;
      resize: none;

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        text-align: left;
      }

      &:focus {
        border-color: $blue--darker;
      }
    }
  }
</style>
